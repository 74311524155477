.component-file-list {
  margin: 8px 0px;
}

.component-file-list .ant-typography {
  margin-bottom: 0px;
}

.component-file-list .view-button {
  padding: inherit;
}
.component-file-list .ant-divider {
  margin: 12px 0px;
}
