.highlight {
  background-color: #f9d100;
}

.ant-table-container {
  overflow-x: scroll;
}

.ant-table-thead > tr > th {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.ant-table-tbody > tr > td {
  padding-top: 7px !important; 
  padding-bottom: 7px !important;
}

.ant-table-tbody > tr.ant-table-measure-row > td {
  padding-top: 0px !important; 
  padding-bottom: 0px !important;
}

.ant-table-cell {
  overflow-y: hidden;
}

.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-cell {
  background-color: #d4e2fc !important;
}

.ant-table-footer {
  padding: 0px !important;
  background-color: #fff !important;
  margin-top: 1px;
}

.table-footer > div {
  padding: 16px 16px;
  box-shadow: 0px 0px 0px 1px #e2e2e2;
}

.table-footer
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 20px;
}

.table-footer .ant-select-selector .ant-select-selection-item {
  display: inline-flex;
  align-items: center;
}

.table-footer .footer-pageSize .ant-select .ant-select-selector,
.table-footer .footer-content .ant-select .ant-select-selector {
  border: none;
  background-color: transparent;
}
.table-footer .footer-pageSize .ant-select .ant-select-arrow,
.table-footer .footer-content .ant-select .ant-select-arrow {
  top: 25%;
}

.table-footer .footer-control {
  border: none;
  border-radius: 0px !important;
  box-shadow: 0px 0px 0px 1px #e2e2e2;
  width: 5rem;
}

.table-footer .footer-control.ant-btn {
  height: unset;
}

.table-footer .footer-control.ant-btn span {
  padding: 6px;
}

.table-footer .footer-control .ant-col:hover {
  background-color: #fafafa;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: #F6F6F6;
}
/* .table-striped-rows thead {
  background-color: #123456;
} */