#page-request-immediately-view {
  padding: 24px;
}

#page-request-immediately-view .container-title {
  margin-bottom: 24px;
}

#page-request-immediately-view .container-title h4.ant-typography {
  margin-bottom: 0px;
}

#page-request-immediately-view
  .container-title
  .ant-btn-primary
  > .ant-typography {
  color: #ffffff;
}

Select .ant-select-selector {
  border-radius: 8px;
}

.FieldInputStyle,
.FieldInputStyle .ant-input,
.FieldInputStyle.ant-input-number,
.FieldInputStyle.ant-select:not(.ant-select-customize-input)
  > .ant-select-selector,
.FieldInputStyle.ant-picker {
  width: 100%;
  border-radius: 8px;
}

.FieldInputStyle.ant-input-number .ant-input-number-input {
  text-align: end;
}

.FormItemInputStyle label {
  width: 100px;
  margin-bottom: 0px;
}
