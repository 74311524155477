.d-flex {
  display: flex !important;
}

.select-table .ant-select-selector {
  border: none !important;
}

.select-table .ant-select-focused div.ant-select-selector {
  box-shadow: none !important;
}

.select-table .ant-form-item-control-input {
  min-height: auto !important;
}

.select-table {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 8px !important;
  padding: 3px 0px;
}

.select-table .ant-select-selection-placeholder,
.select-table .ant-select-selection-item,
.select-table .ant-select-selection-search .ant-select-selection-search-input,
.select-table-date .ant-select-selection-placeholder,
.select-table-date .ant-select-selection-item {
  padding-left: 30px !important;
  padding-right: 0px !important;
}

.select-table .ant-select,
.select-table-date .ant-select {
  width: 100% !important;
}

.select-table .ant-select-arrow,
.select-table-date .ant-select-arrow {
  width: 90%;
  left: 10px;
}

.select-table-date .ant-select-selector {
  border: none !important;
}

.select-table-date .ant-select-focused div.ant-select-selector {
  box-shadow: none !important;
}

.select-table-date {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 8px 0px 0px 8px !important;
  padding: 3px;
  flex: 1 auto;
}

.ant-picker.date-picker-table {
  border-radius: 0px 8px 8px 0px !important;
  flex: 1 auto;
}

.checkbox-table .ant-form-item-control-input-content {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 4px 11px;
}
