@font-face {
  font-family: "FC Iconic";
  src: local("FC Iconic"), url(assets/fonts/FC_Iconic.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "FC Iconic" !important;
  /* font-family: "FCIconic", apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  margin: 0;
  height: 100%;
  /* overflow: scroll; */
}

.ant-form-item-control-input-content input,
.ant-input-affix-wrapper,
button {
  border-radius: 8px !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-table-thead > tr > th {
  background-color: #e7e7e7 !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 14px;
  z-index: 1;
}

.new-line {
  white-space: pre-line;
}

.d-none {
  display: none;
}