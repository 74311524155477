.custom-modal .ant-modal-content {
  border-radius: 10px;
}
.custom-modal .ant-modal-content .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.custom-modal .ant-modal-content .ant-modal-footer {
  border-radius: 0 0 10px 10px;
}

.custom-modal .ant-modal-content textarea {
  border-radius: 5px;
}

.custom-modal
  .ant-modal-content
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 5px;
  margin-bottom: 8px;
}
