.container-layout-content {
  background-color: #ffffff;
  padding: 24px;
}

.header-left {
  display: flex;
  align-items: center;
  width: 50%;
}

.layout-container-header.ant-layout-header {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  padding: 0px 28.5px;
}

.layout-container-header.ant-layout-header > div {
  display: flex;
  align-items: center;
}

.layout-container-header.ant-layout-header > div:first-child {
  width: 100%;
}

.layout-container-header.ant-layout-header > div:last-child {
  min-width: 300px;
  justify-content: flex-end;
}

.layout-container-header.ant-layout-header .hamburger-menu {
  font-size: 18px;
  margin-right: 22px;
}

.layout-container-header.ant-layout-header .container-logo-image {
  width: 171.5px;
  min-width: 171.5px;
  max-width: 171.5px;
  display: flex;
  align-items: center;
}

.layout-container-header.ant-layout-header .container-logo-image .logo-image {
  width: 60px;
  height: 40px;
}

.layout-container-header.ant-layout-header .container-user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.layout-container-header.ant-layout-header .container-user-info .user-info > p {
  text-align: right;
  margin: 0px;
}

.layout-container-header.ant-layout-header
  .container-user-info
  .user-info
  .user-info-fullname {
  color: #545454;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.layout-container-header.ant-layout-header
  .container-user-info
  .user-info
  .user-info-role {
  color: #757575;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.48px;
}

.layout-container-sidebar.ant-layout-sider {
  width: 240px !important;
  height: calc(100vh - 64px);
  min-width: 240px !important;
  max-width: 240px !important;
  background-color: #f6f6f6;
  padding: 15px 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.layout-container-sidebar.ant-layout-sider.ant-layout-sider-collapsed {
  width: 0px !important;
  min-width: 0px !important;
  max-width: 0px !important;
  height: calc(100vh - 64px);
}

.layout-container-sidebar.ant-layout-sider.ant-layout-sider-collapsed
  .side-navigation {
  display: flex;
  align-items: center;
}

.layout-container-sidebar.ant-layout-sider .container-side-navigation {
  background-color: inherit;
  padding-bottom: 15px;
}

.layout-container-sidebar.ant-layout-sider
  .container-side-navigation
  .side-navigation {
  width: 224px;
  margin: auto;
  color: #545454;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.28px;
  border-radius: 8px;
  border-right: none;
}

.layout-container-sidebar.ant-layout-sider
  .container-side-navigation
  .side-navigation.ant-menu-item-selected {
  background-color: #d4e2fc;
  color: #174291;
}

.layout-container-sidebar.ant-layout-sider
  .container-side-navigation
  .side-navigation.ant-menu-item-selected
  path {
  fill: #174291;
}

.layout-container-sidebar.ant-layout-sider
  .container-side-navigation
  .side-navigation.ant-menu-item-selected::after {
  display: none;
}

.layout-container-footer.ant-layout-footer {
  background-color: #ffffff;
  color: #4d5358;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-align: center;
}
