Select .ant-select-selector {
  border-radius: 8px;
}

.FieldInputStyle,
.FieldInputStyle .ant-input,
.FieldInputStyle.ant-input-number,
.FieldInputStyle.ant-select:not(.ant-select-customize-input)
  > .ant-select-selector,
.FieldInputStyle.ant-picker {
  width: 100%;
  border-radius: 8px;
}

.FieldInputStyle.ant-input-number .ant-input-number-input {
  text-align: end;
}

.ant-picker.date-picker-table {
  border-radius: 8px !important;
  flex: 1 auto;
}

.FormItemInputStyle label {
  width: 100px;
  margin-bottom: 0px;
}
