.Configurations {
    padding-left: 3%;
}

.Configurations h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 36px;
    letter-spacing: 0.0016em;
    text-align: left;
}

.Configurations .ant-form-vertical .ant-form-item-label{
    padding-bottom: 4px;
    color: #545454;
}

.Configurations .ant-form-item-extra{
    padding-top: 8px;
    color: #545454;
}

.Configurations Input {
    width: 272px;
}