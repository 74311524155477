.FieldInputStyle,
.FieldInputStyle .ant-input,
.FieldInputStyle.ant-input-number,
.FieldInputStyle.ant-select:not(.ant-select-customize-input)
  > .ant-select-selector,
.FieldInputStyle.ant-picker {
  width: 100%;
  border-radius: 8px;
}

.DetailsTable .ant-table-thead > tr > th,
.DetailsTable .ant-table-tbody > tr > td {
  padding: 8px;
}

.DetailsTable .ant-table-cell {
  overflow-y: unset;
}

.DetailsTable .ant-table-thead .ant-table-cell-fix-left-last::after {
  position: unset !important;
}

.DetailsTable .ant-table-footer {
  margin-top: 0px;
}

.ant-list-item {
  border-bottom: unset;
}

.DetailsTable {
  margin-bottom: 32px;
}

.DetailsTable .ant-table-placeholder {
  display: none;
  margin-bottom: 32px;
}

.DetailsTable
  ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  display: none;
}

div.ant-popover-inner-content {
  padding: 0;
}

.ant-popover.ant-popover-placement-bottom {
  z-index: 10;
}

/* Variable */
:root {
  --drawer-header-height: 55px;
  --drawer-footer-height: 120px;
}

/* Fix Size Custom Drawer */
.custom-drawer .ant-drawer-content {
  height: 100vh !important;
  position: relative;
}

.custom-drawer .ant-drawer-header {
  height: var(--drawer-header-height) !important;
  padding: 16px 0px;
}

.custom-drawer .ant-drawer-body {
  height: calc(100vh - var(--drawer-header-height)) !important;
  padding: 0px;
}

.custom-drawer .ant-drawer-body .custom-drawer-content {
  height: calc(
    100% - (var(--drawer-header-height) + var(--drawer-footer-height))
  ) !important;
  min-height: calc(
    100vh - (var(--drawer-header-height) + var(--drawer-footer-height))
  ) !important;
  padding-top: 10px;
  overflow-y: scroll;
}

.custom-drawer .ant-drawer-body .custom-drawer-content .ant-tabs .ant-tabs-nav {
  padding: 0px 24px;
}

.custom-drawer
  .ant-drawer-body
  .custom-drawer-content
  .tab-header-content
  .ant-row {
  padding: 10px 24px;
}

.custom-drawer
  .ant-drawer-body
  .custom-drawer-content
  .tab-header-content
  .ant-row:nth-child(odd) {
  background-color: #f6f6f6;
}

.custom-drawer .ant-drawer-body .custom-drawer-footer {
  width: 100%;
  height: var(--drawer-footer-height) !important;
  border-top: 1px solid #f0f0f0;
  padding: 10px 24px;
  position: absolute;
  bottom: 0px;
}

.custom-drawer .ant-drawer-body .custom-drawer-footer > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 100%;
}
